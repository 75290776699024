import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#5194e8",
                secondary: "#FFD862",
                accent: "#002C63",
                error: '#c40',
                success: '#08ce79',
                text: '#ffffff'
            },
        },
        options: { customProperties: true },
    },
});
