<template>
  <v-overlay opacity="1" absolute :value="loading">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-overlay>
</template>
<script>
export default {
  name: "loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay-fill {
  height: 100%;
  width: 100%;
}
.loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 35px 5px;
}
</style>