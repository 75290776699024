const Papa = require("papaparse");

export async function exportAndDownload(headers, data) {

    var formatedData = {
        headers: [],
        data: [],
    };
    formatedData.data = data;
    headers.forEach((header) => {
        formatedData.headers.push(header.value);
    });
    var csv = Papa.unparse(formatedData);
    // headers.forEach((header) => {
    //     csv = csv.replace(header.key, header.value);
    // });

    var csvFile;
    var downloadLink;

    csvFile = new Blob([csv], {
        type: "text/csv"
    });

    downloadLink = document.createElement("a");

    var fileName =
        new Date(Date.now())
            .toLocaleString()
            .split(",")[0]
            .replace("/", "_")
            .replace(" ", "_") + ".csv";
    fileName = `relatorio_${fileName}`;

    downloadLink.download = fileName;

    downloadLink.href = window.URL.createObjectURL(csvFile);

    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);

    downloadLink.click();
}
