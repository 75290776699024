import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "./store"
import Home from '../views/Home'
import Report from '../views/Report'
import Login from '../views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/relatorio',
    name: 'Report',
    component: Report,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hideForAuth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const hideForAuth = to.matched.some((record) => record.meta.hideForAuth);
  const loggedIn = store.getters.user.loggedIn;

  if (requiresAuth && !loggedIn) {
    return next({ path: "/login" });
  } else if (hideForAuth && loggedIn) {
    return next({ path: "/" });
  }
  next();
});

export default router
