<template>
  <v-container
    ><v-toolbar>
      <v-btn color="accent" @click="exportCSV">Exportar .CSV</v-btn>
      <v-spacer />
      <v-btn color="accent" @click="exit">Sair</v-btn>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>
<script>
import { get } from "../scripts/report";
import { exportAndDownload } from "../scripts/export";
import { signOut } from "../scripts/auth";
export default {
  props: ["track"],
  data: () => ({
    data: [],
    items: [],
    headers: [
      { text: "Criado em", value: "createdAt" },
      { text: "Email", value: "email" },
      { text: "Apelido", value: "nome" },
    ],
    loading: false,
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getPage();
    },
    getPage() {
      this.loading = true;
      get(this.handleDataLoadSuccessCallback, this.handleDataLoadErrorCallback);
    },
    handleDataLoadSuccessCallback(data) {
      this.data = data;
      this.formatData();
      this.loading = false;
    },
    handleDataLoadErrorCallback(error) {
      console.log(error);
      this.loading = false;
    },
    formatData() {
      this.data.forEach((d) => {
        var date = d.createdAt.toDate();
        d.createdAt = [
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear(),
        ].join("/");
        d.email = d.email.toLowerCase();
      });
      this.items = this.data;
    },
    exportCSV() {
      exportAndDownload(this.headers, this.items);
    },
    exit() {
      signOut(() => this.$router.push("/login"));
    },
  },
};
</script>