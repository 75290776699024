<template>
  <div class="wrapper">
    <div id="unity-container" class="unity-desktop">
      <canvas id="unity-canvas" width="960" height="600"></canvas>
      <div id="unity-loading-bar">
        <div id="pepita-logo"></div>
        <div id="unity-progress-bar-empty">
          <div id="unity-progress-bar-full"></div>
        </div>
      </div>
      <div id="unity-warning"></div>
      <div id="unity-footer">
        <div id="unity-fullscreen-button"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { db } from "../scripts/firebaseConfig";
export default {
  data: () => ({
    unityInstance: null,
    container: null,
    canvas: null,
    loadingBar: null,
    progressBarFull: null,
    fullscreenButton: null,
    warningBanner: null,
  }),
  computed: {
    buildUrl: function () {
      return "unity/Build";
    },
    loaderUrl: function () {
      return this.buildUrl + "/Build.loader.js";
    },
    config: function () {
      return {
        dataUrl: this.buildUrl + "/Build.data",
        frameworkUrl: this.buildUrl + "/Build.framework.js",
        codeUrl: this.buildUrl + "/Build.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "DefaultCompany",
        productName: "a-brecha",
        productVersion: "1.0",
        showBanner: this.unityShowBanner,
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      window.handleTicket = this.handleTicket;

      this.container = document.querySelector("#unity-container");
      this.canvas = document.querySelector("#unity-canvas");
      this.loadingBar = document.querySelector("#unity-loading-bar");
      this.progressBarFull = document.querySelector("#unity-progress-bar-full");
      this.fullscreenButton = document.querySelector(
        "#unity-fullscreen-button"
      );
      this.warningBanner = document.querySelector("#unity-warning");

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // Mobile device style: fill the whole browser client area with the game canvas:

        var meta = document.createElement("meta");
        meta.name = "viewport";
        meta.content =
          "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
        document.getElementsByTagName("head")[0].appendChild(meta);
        this.container.className = "unity-mobile";

        // To lower canvas resolution on mobile devices to gain some
        // performance, uncomment the following line:
        // config.devicePixelRatio = 1;

        this.canvas.style.width = window.innerWidth + "px";
        this.canvas.style.height = window.innerHeight + "px";

        this.unityShowBanner("O jogo não é executado em dispositivos móveis.");
      } else {
        // Desktop style: Render the game canvas in a window that can be maximized to fullscreen:

        this.canvas.style.width = "960px";
        this.canvas.style.height = "600px";
      }

      this.loadingBar.style.display = "block";

      var script = document.createElement("script");
      script.src = this.loaderUrl;
      script.onload = () => {
        /* eslint-disable */
        this.unityInstance = createUnityInstance(
          this.canvas,
          this.config,
          (progress) => {
            this.progressBarFull.style.width = 100 * progress + "%";
          }
        )
          .then(this.handleInstanceLoad)
          .catch((message) => {
            alert(message);
          });
      };
      document.body.appendChild(script);
    },
    unityShowBanner(msg, type) {
      var div = document.createElement("div");
      div.innerHTML = msg;
      this.warningBanner.appendChild(div);
      if (type == "error") div.style = "background: red; padding: 10px;";
      else {
        if (type == "warning") div.style = "background: yellow; padding: 10px;";
        setTimeout(function () {
          this.warningBanner.removeChild(div);
          this.updateBannerVisibility();
        }, 5000);
      }
      this.updateBannerVisibility();
    },
    updateBannerVisibility() {
      this.warningBanner.style.display = this.warningBanner.children.length
        ? "block"
        : "none";
    },
    handleInstanceLoad(unityInstance) {
      this.unityInstance = unityInstance;
      this.loadingBar.style.display = "none";
      this.fullscreenButton.onclick = () => {
        this.unityInstance.SetFullscreen(1);
      };
      // this.unityInstance.on("SendTicket", this.handleTicket);
    },
    handleTicket(name, email) {
      console.log(name, email);
      if (email) {
        db.collection("ticket")
          .doc(email)
          .set({ email: email, nome: name, createdAt: new Date() });
      }
    },
  },
};
</script>
<style>
@import "../assets/style.css";
</style>