import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import store from "./store";

const firebaseConfig = {
  apiKey: "AIzaSyB9YKC6FinNcptAjnLmdWgJ0fnPTIEAZLs",
  authDomain: "atitude-b3.firebaseapp.com",
  projectId: "atitude-b3",
  storageBucket: "atitude-b3.appspot.com",
  messagingSenderId: "382107779562",
  appId: "1:382107779562:web:877d8f4fd1fd9fc7bd3293",
  measurementId: "G-5YLMRZX0TB"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const authPersistance = firebase.auth.Auth.Persistence.SESSION;

auth.onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

const db = firebase.firestore();

db.settings({
  experimentalForceLongPolling: true
});

export { db, auth, authPersistance, };
