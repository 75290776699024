import { auth, authPersistance } from "./firebaseConfig";

function signInUser(form, successCallback, errorCallback) {
    auth
        .setPersistence(authPersistance)
        .then(function () {
            return auth
                .signInWithEmailAndPassword(form.email, form.password)
                .then(() => {
                    auth.currentUser.getIdToken(true);
                    successCallback();
                })
                .catch((err) => {
                    errorCallback(err.code);
                });
        })
        .catch(function (err) {
            errorCallback(err.code);
        });
}

function signOut(callback) {
    auth.signOut();
    callback();
}

export {
    signInUser,
    signOut,
};

