<template>
  <game />
  <!-- <v-container fluid fill-height>
    <v-row justify="center">
    <h1>Em manutenção, volte mais tarde</h1>
    </v-row>
  </v-container> -->
</template>

<script>
import Game from "../components/Game.vue";
export default {
  name: "Home",

  components: { Game },
};
</script>
