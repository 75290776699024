<template>
  <v-container fill-height fluid pa-0 class="gradient-bg">
    <v-row justify="center">
      <div
        class="form-container"
        :class="{ mobile: $vuetify.breakpoint.mobile }"
      >
        <v-sheet rounded class="pa-4" style="postition: relative">
          <loader :loading="submited" />
          <v-container mb-4>
            <v-row align-content="center" justify="center">
              <Logo size="lg" class="mr-2" />
              <h1
                class="
                  text-h4
                  font-weight-bold
                  text-center text-uppercase
                  my-auto
                "
              >
                Iniciar Sessão
              </h1>
            </v-row>
          </v-container>
          <v-form action="#" v-model="valid" @submit.prevent="submit">
            <v-text-field
              label="Email"
              :rules="emailRules"
              required
              v-model="email"
            ></v-text-field>
            <v-text-field
              label="Senha"
              :rules="notEmptyRules"
              type="password"
              required
              v-model="password"
            ></v-text-field>
            <v-btn
              color="secondary"
              rounded
              elevation="0"
              type="submit"
              class="mt-4 accent--text px-8"
              :disabled="!valid"
            >
              Entrar
            </v-btn>
          </v-form>
          <v-snackbar color="error" v-model="error">
            {{ errorMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" icon v-bind="attrs" @click="error = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-sheet>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import { signInUser } from "../scripts/auth";
import Logo from "../components/Logo.vue";
import Loader from "../components/Loader.vue";
export default {
  components: { Loader, Logo },
  data() {
    return {
      email: "",
      password: "",
      submited: false,
      valid: false,
      error: false,
      errorMessage: null,
      emailRules: [
        (value) => !!value || "Campo necessário.",
        (value) => /.+@.+/.test(value) || "Email não é válido",
      ],
      notEmptyRules: [(value) => !!value || "Campo necessário."],
    };
  },
  computed: {
    form: function () {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    submit() {
      if (!this.valid) return;
      signInUser(this.form, this.successCallback, this.errorCallback);
      this.submited = true;
      this.error = false;
    },
    successCallback() {
      this.submited = false;
      this.$router.push("/relatorio");
    },
    errorCallback(err) {
      console.log(err);
      this.password = "";
      this.submited = false;
      this.errorMessage = err;
      this.error = true;
    },
  },
};
</script>
