<template>
  <svg
    viewBox="0 0 1080 1080"
    xmlns="http://www.w3.org/2000/svg"
    alt="B3"
    :class="['logo', size, 'logo-' + color]"
  >
    <path
      class="st0"
      d="M597.77,561.33c18.15-23.97,29.07-53.8,29.07-86.23c0-78.53-63.33-142.45-141.12-142.45H227.77v196.39v88.52
		v196.39h131.54h149.11c77.82,0,141.11-63.9,141.11-142.48C649.53,627.14,629.35,587.49,597.77,561.33 M344.85,421.18h117.71
		c29.47,0,53.41,24.2,53.41,53.92c0,29.76-23.95,53.93-53.41,53.93H344.85V421.18z M473.12,725.4H344.85V617.55h128.27
		c29.45,0,53.41,24.19,53.41,53.91C526.53,701.22,502.57,725.4,473.12,725.4"
    />
    <path
      class="st0"
      d="M1030.47,246.79c0,17.49-6.62,31.83-19.87,43.04c-13.27,11.15-30.28,16.78-51.02,16.78
		c-19.5,0-35.95-5.18-49.36-15.58c-13.38-10.34-21.34-24.45-23.91-42.36h52.56c3.97,10.36,11,15.6,21.28,15.6
		c6.31,0,11.47-1.69,15.55-5.06c4.06-3.36,6.08-7.8,6.08-13.25c0-5.29-1.97-9.5-5.94-12.71c-3.96-3.2-9.49-4.78-16.52-4.78h-17.82
		v-37.16h16.47c6.68,0,12.01-1.48,15.98-4.5c3.96-3.01,5.95-6.97,5.95-11.88c0-5.46-1.85-9.85-5.56-13.13
		c-3.69-3.26-8.69-4.9-15.03-4.9c-6.32,0-11.48,2.18-15.55,6.57c-2.43,2.63-4.2,6.12-5.45,10.35h-50.14
		c2.91-18.38,10.69-32.72,23.41-43c12.72-10.29,28.52-15.44,47.49-15.44c20.01,0,36.49,5.47,49.36,16.4
		c12.89,10.94,19.35,24.86,19.35,41.79c0,15.49-6.31,27.32-18.93,35.52C1023.27,217.27,1030.47,229.83,1030.47,246.79"
    />
    <polygon
      class="st0"
      points="228.89,240.43 228.89,181.48 49.29,181.48 49.29,965.32 228.89,965.32 228.89,906.17 107.51,906.17 
		107.51,240.43 	"
    />
    <polygon
      class="st0"
      points="646.16,906.17 646.16,965.32 825.74,965.32 825.74,181.48 646.16,181.48 646.16,240.43 767.53,240.43 
		767.53,906.17 	"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: {
      default: "sm",
      type: String,
    },
    color: {
      default: "light",
      type: String,
    },
  },
};
</script>
<style scoped>
.logo.sm {
  width: 36px;
}
.logo.md {
  width: 48px;
}
.logo.lg {
  width: 60px;
}
.logo.logo-light {
  fill: white;
}
.logo.logo-primary {
  fill: var(--v-accent-base);
}
</style>