import Vue from 'vue'
import App from './App.vue'
import router from './scripts/router'
import store from './scripts/store'
import vuetify from './scripts/vuetify'
import "./scripts/firebaseConfig";
import { auth } from "./scripts/firebaseConfig";

Vue.config.productionTip = false

let app;

auth.onAuthStateChanged(async user => {
  store.dispatch("fetchUser", user);
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});